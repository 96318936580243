@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./nprogress.css');
@import url('./react-date-picker.css');

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Open Sans';
  background: #fafafa;
  scroll-behavior: smooth !important;
}
h1,
h2,
h3,
h4 {
  @apply font-Outfit;
  font-weight: 800
}

p,
button,
a,
span {
  font-family: 'Work sans';
}

a {
  color: inherit;
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
.hire {
  position: relative;
  margin-left: 1.7rem;
}
.hire:after {
  content: url(../../public/assets/circle.png);
  position: absolute;
  top: -1rem;
  left: -0.7rem;
  transform: scale(1.2);
}
@media screen and (max-width: 760px) {
  .hire:after {
    content: url(../../public/assets/circle.png);
    position: absolute;
    top: -2.2rem;
    left: -3.5rem;
    transform: scale(0.6);
  }
}

.nav-hover {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.nav-hover:after {
  content: '';
  width: 0;
  border-bottom: 2px solid #16bf44;
  transition: all 200ms ease-in-out;
}
.nav-hover:hover:after {
  width: 30px;
}
.active:after {
  content: '';
  width: 30px;
  border-bottom: 2px solid #16bf44;
  transition: all 200ms ease-in-out;
}

.nav-dashboard-hover {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.nav-dashboard-hover:after {
  content: '';
  width: 0;
  border-bottom: 2px solid white;
  transition: all 200ms ease-in-out;
}

.nav-dashboard-hover:hover:after {
  width: 30px;
}
.nav-dashboard-recruiter-hover {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.nav-dashboard-recruiter-hover:after {
  content: '';
  width: 0;
  border-bottom: 2px solid black;
  transition: all 200ms ease-in-out;
}

.nav-dashboard-recruiter-hover:hover:after {
  width: 30px;
}

.navactive:after {
  content: '';
  width: 30px;
  border-bottom: 2px solid white;
  transition: all 200ms ease-in-out;
}

.navactiveRecruiter:after {
  content: '';
  width: 30px;
  border-bottom: 2px solid black;
  transition: all 200ms ease-in-out;
}

.fader {
  /* background: rgb(255,255,255); */
  background: linear-gradient(
    90deg,
    rgba(248, 247, 249, 1) 0%,
    rgba(253, 29, 29, 0.015118790496760237) 50%,
    rgba(242, 242, 240, 1) 100%
  );
}
.ribbon {
  animation: animate 12s linear infinite;
}
@keyframes animate {
  0% {
    transform: translateX(5%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.ribbonJobs {
  animation: animateJobs 40s linear infinite;
}

.ribbonJobs2 {
  animation: animateJobs2 40s linear infinite;
}

@keyframes animateJobs {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-300%);
  }
}

@keyframes animateJobs2 {
  0% {
    transform: translateY(-0%);
  }
  100% {
    transform: translateY(300%);
  }
}


@keyframes scale-shadow {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}


section .box {
  width: 600px;
  height: 430px;
  display: flex;
  justify-content: center;
  position: absolute;
  align-items: center;
  transform: skew(5deg);
  background: url(../../public/assets/blurImage2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

section .box:nth-child(1) {
  filter: blur(10px);
}

.circle {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 300px;
  border: 2px solid #fff;
  z-index: 1;
  border-radius: 50%;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.25);
}

.blurcard {
  filter: blur(10px);
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 9px;
}
.round input[type='checkbox'] {
  visibility: hidden;
}

.round input[type='checkbox']:checked + label {
  background-color: #16bf44;
  border-color: #16bf44;
}

.round input[type='checkbox']:checked + label:after {
  opacity: 1;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.loginBack {
  background: url(../../public/assets/anotherBlack.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.onboardBack {
  background: url(../../public/assets/onboardBlack.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* Phone Input */
/* Remove outline */
.PhoneInputInput {
  @apply bg-transparent outline-none;
}

input[type='radio'] {
  cursor: pointer;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.2rem;
  height: 1.2rem;
  border: 0.2rem solid #a6a6a6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* verification code */

.verification-input-container > div {
  width: 100%;
  justify-content: space-between;
  font-size: 1.5rem;
}

.verification-input-container input {
  width: 100% !important;
  height: 50px;
}

.form-check-input:checked[type='checkbox'] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' strokeLinecap='round' strokeLinejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.article-format ul {
  list-style-type: disc;
}

/* WYSIWYG RESET */

.job-description ul {
  font-family: 'work sans', sans-serif;
  font-weight: 700;
  display: block;
  list-style-type: disc;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}

.job-description p > strong,
.job-description h1 > strong,
.job-description h2 > strong,
.job-description h3 > strong,
.job-description h4 > strong,
.job-description h5 > strong {
  display: block;
  font-weight: 500;
  margin-top: 1rem;
}

.job-description li,
.job-description p {
  line-height: 2;
  font-weight: 300;
  font-size: 14px;
}

.job-description h1 {
  display: block;
  font-size: 1.8em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 500;
}

.job-description h2 {
  display: block;
  font-size: 1.2em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 500;
}

.profile-picture {
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}


.flexibleHeight{
  height: calc(100vh - 156px)
 }

  @media only screen and (max-width: 600px) {
    .flexibleHeight {
      height: 100vh;
    }
  }

  .adsbygoogle iframe {
    width: 100% !important;
    height: auto !important;
}

#aswift_1_host {
  width: 100% !important;
}